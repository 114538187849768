import { PermissionOptions } from '@shared/model/permission-options.model';

export function hasPermissions(
  userFeatures: string[],
  options: PermissionOptions
): boolean {
  if (!userFeatures) {
    return true;
  }
  if (
    (Array.isArray(options.code) && options.code.length === 0) ||
    options.code === '' ||
    options.code === undefined
  ) {
    return true;
  } else {
    const features = [...userFeatures];

    const codes: string[] = [];
    if (typeof options.code === 'string') {
      codes.push(...(options.code as string).split(','));
    } else {
      codes.push(...(options.code as []));
    }

    let found = false;

    for (const item of codes) {
      found = features.some((r) =>
        options.fullMatch ? r === item : r.startsWith(item.toString())
      );

      if (found) {
        return found;
      }
    }
    return false;
  }
}
